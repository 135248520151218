<main>
    
        <!-- download="krisana-crockett.pdf"
        target="_blank"
        href="../assets/resume.pdf" -->
    <app-nav-button class="resume"
        iconClass="bit bi-file-text-fill"
        color="#68D9DB"
        caption="Résumé"
        [action]="showResumeModal"
    ></app-nav-button>
    <app-nav-button class="linkedin"
        href="https://www.linkedin.com/in/krisana-crockett"
        target="krisana-linkedin"
        iconClass="bi bi-linkedin"
        color="#FEA7F5"
        caption="LinkedIn"></app-nav-button>
    <app-nav-button class="contact"
        href="mailto:Krisana.Crockett@Gmail.com"
        iconClass="bi bi-envelope-fill"
        color="#6F5CCB"
        caption="Email"></app-nav-button>
</main>
<div id="myModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Which résumé version would you like to see?</h5>
            </div>
            <div class="modal-body">
                <a class="pdf-version-button" target="resume" href="assets/resume.pdf" download="Krisana_Crockett">
                    <div class="btn pdf-version-button">
                        <div class="pretty-version-label">Pretty Version</div>
                    </div>
                </a>
                <a class="pdf-version-button" target="resume" href="assets/resume.docx" download="Krisana_Crockett">
                    <div type="button" class="btn pdf-version-button">
                        <div class="plain-version-label">Plain Version</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
