import { Component, OnInit } from '@angular/core';

declare const bootstrap: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit {
  showResumeModal = () => {
    const modalDiv = document.getElementById("myModal");
    const modal = new bootstrap.Modal(modalDiv);
    modal.show();
  };

  constructor() { }

  ngOnInit(): void {
  }

}
